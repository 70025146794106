<template>
  <!-- <div class="brand_info" v-if="userInfo.brand_info" @click="showBrand=true">{{userInfo.brand_info.brand_name}} <a href="javascritp:;">-修改</a> </div>  -->
  <div class="login tw-flex tw-flex-col  tw-items-center p-t-50 tw-relative" v-if="!isActive" :style="hiddenButton?'height:200vh':''">
    <div id="logo" class="m-b-137">
      <img :src="info.brand_info&&info.brand_info.brand_logo" alt="" class="width-64 height-64 tw-rounded-full">
    </div>
    <div id="loginFrom">
      <div class="p-t-14 option m-b-21" @click="openBrandList">
        <div class="main tw-flex tw-justify-between" v-if="userData.brand_info"><span>{{userData.brand_info.brand_name}}</span><span>
            <van-icon name="arrow-down" />
          </span></div>
        <div class="tw-flex tw-justify-between" v-else><span class="desc">请选择品牌商</span><span>
            <van-icon name="arrow-down" />
          </span></div>
      </div>
      <input type="text" placeholder="请输入账号" class="tw-block p-b-12 m-b-21" v-model="form.account" @focus="hiddenButton=true" @blur="hiddenButton=false">
      <input type="password" placeholder="请输入密码" class="tw-block  p-b-12" v-model="form.password" @focus="hiddenButton=true" @blur="hiddenButton=false">
      <!-- <van-dropdown-menu>
        <van-dropdown-item v-model="br" :options="columns" />
      </van-dropdown-menu> -->
      <div class="p-t-14 tw-flex tw-justify-end ">
        <van-checkbox v-model="checked" checked-color="#4290FF" shape="square"><span class="tw-text-theme tw-font-medium fz-14">记住密码</span></van-checkbox>
      </div>
      <div id="button" @click="login" class="tw-absolute tw-rounded-full height-44 s_lin_h44 tw-text-center tw-text-white" v-if="!hiddenButton">登陆</div>
    </div>
  </div>
  <div class="login tw-flex tw-flex-col  tw-items-center p-t-65 tw-relative" v-else>
    <div id="loginFrom" class="activeFrom">
      <input type="text" placeholder="请输入激活码" class="tw-block p-b-12 m-b-21  m-t-40" v-model="active_code" @focus="hiddenButton=true" @blur="hiddenButton=false">
      <div id="button" @click="activeDevice" class="tw-absolute tw-rounded-full height-44 s_lin_h44 tw-text-center tw-text-white" v-if="!hiddenButton">激活</div>
    </div>
  </div>
  <van-action-sheet v-model:show="showBrand" :actions="columns" @select="onConfirm" />

  <!-- <van-popup v-model:show="showBrand" title="" position="bottom" :style="{ height: '30%' }">
    <van-picker title=" " :columns="columns" @change="onConfirm" @cancel="showBrand=false" :columns-field-names="{text:'brand_name'}" :show-toolbar="false"/>
  </van-popup> -->
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { setStore, getStore } from "@/utils/util";
import { onMounted } from "@vue/runtime-core";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
import store from "@/store";
import { pdaActive, getUserInfo, pdaLogin, getBrandsList } from "@/api/index";
import { iscode } from "@/utils/app";
export default {
  name: "login",
  setup() {
    const checked = ref(false);
    const form = ref({
      account: "",
      password: "",
    });
    const hiddenButton = ref(false);
    const router = useRouter();
    const route = useRoute();
    // 登录
    const login = async () => {
      if (checked) {
        setStore("Account", form.value);
      } else {
        removeStore("Account");
      }
      let result = await pdaLogin(form.value)
        .then((res) => res.data)
        .catch((error) => error);
      if (iscode(result, true)) {
        setStore("token", result.data.token);
        router.push({ path: "/index" });
      }
    };
    const info = computed({
      get() {
        return store.state.user.info;
      },
    });
    // 激活状态
    const isActive = ref(false);
    const active_code = ref("");
    const activeDevice = async () => {
      if (!active_code.value) {
        Toast.error("请输入激活码");
        return;
      }
      let result = await pdaActive(active_code.value, {
        IMEI: getStore("IMEI"),
        active_code: active_code.value,
      })
        .then((res) => res.data)
        .catch((error) => error);
      if (iscode(result, true)) {
        updateUserData();
        isActive.value = false;
      }
    };
    var updateUserData = async () => {
      let userInfo = await getUserInfo()
        .then((res) => res.data)
        .catch((error) => error);
      if (userInfo.code == 20001) {
        isActive.value = true;
      }

      store.commit("user/SET_USER_INFO", userInfo.data);
    };
    const userData = computed({
      get() {
        return store.state.user.info;
      },
    });
    const showBrand = ref(false);
    const columns = ref([]);
    const getBList = async () => {
      let result = await getBrandsList()
        .then((res) => res.data)
        .catch((error) => error);

      columns.value = result.data;
      columns.value.map((v) => (v["name"] = v.brand_name));
      console.log(columns.value,232323);

    };

    const onConfirm = async (e) => {
      showBrand.value = false;
      setStore("brand_id", e.brand_no);
      let userInfo = await getUserInfo()
        .then((res) => res.data)
        .catch((error) => error);
      store.commit("user/SET_USER_INFO", userInfo.data);
    };
    const openBrandList = async () => {
      showBrand.value = true;
        setStore("brand_id", columns.value[0].brand_no);
      let userInfo = await getUserInfo()
          .then((res) => res.data)
          .catch((error) => error);
        store.commit("user/SET_USER_INFO", userInfo.data);
    };
    var updateUserData = async () => {
      let userInfo = await getUserInfo()
        .then((res) => res.data)
        .catch((error) => error);
      if (userInfo.code == 20001) {
        isActive.value = true;
      }

      store.commit("user/SET_USER_INFO", userInfo.data);
    };
    onMounted(() => {
      if (getStore("Account")) {
        form.value = getStore("Account");
        checked.value = true;
      }
      if (route.query.isAcive) {
        // 需要激活
        isActive.value = true;
      }
      // uniapp 打包
      if (window.plus) {
        setStore("IMEI", plus.device.imei);
        // 在这里调用5+ API
      } else {
        // 兼容老版本的plusready事件
        document.addEventListener("plusready", function () {
          setStore("IMEI", plus.device.imei);
        });
      }
      updateUserData();
      getBList()
    });
    return {
      checked,
      form,
      login,
      isActive,
      activeDevice,
      active_code,
      info,
      hiddenButton,
      userData,
      columns,
      onConfirm,
      showBrand,
      openBrandList,
    };
  },
};
</script>
<style scoped lang="scss">
.brand_info {
  background-color: #fff;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  a {
    color: #68d0ff;
  }
}
.login {
  background-color: #fff;
  font-size: 16px;
  height: 100%;
  #loginFrom {
    input {
      width: 300px;
      border-bottom: 1px solid #d0d0d0;
      font-size: 14px;
      color: #131212;
      font-weight: 600;
      overflow: hidden;
      border-radius: 0;
    }
    input::-webkit-input-placeholder {
      font-size: 14px;
      font-weight: 400;
      color: #d0d0d0;
    }
    input:focus {
      border-bottom: 1px solid #4290ff;
      caret-color: #4290ff;
    }
  }
  #button {
    background: linear-gradient(270deg, #68d0ff 0%, #53a1ff 100%);
    bottom: 39px;
    width: 80%;
    left: 50%;
    transform: translateX(-50%);
  }
  .activeFrom {
    input::-webkit-input-placeholder {
      font-size: 16px;
      font-weight: 500;
      color: #000 !important;
    }
  }
}
.option {
  height: 50px;
  border-bottom: 1px solid #d0d0d0;
  font-size: 14px;
  .desc {
    color: #d0d0d0;
  }
  .main {
    font-size: 14px;
    color: #131212;
    font-weight: 600;
  }
}
</style>
